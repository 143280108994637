var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "box" } }, [
    _c("div", { staticClass: "separate" }, [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "a-card",
            {
              staticStyle: {
                "box-sizing": "border-box",
                "border-radius": "0 !important",
              },
            },
            [
              !_vm.appId
                ? _c("a-divider", [_vm._v("请选择应用APPID")])
                : _vm.noConfigText
                ? _c("a-divider", [_vm._v("您尚未配置任何支付方式")])
                : _vm._e(),
              _vm.payTestShow()
                ? _c(
                    "div",
                    { staticClass: "paydemo", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "div",
                        { staticClass: "paydemo-type-content" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showTitle("WX"),
                                  expression: "showTitle('WX')",
                                },
                              ],
                              staticClass: "paydemo-type-name article-title",
                            },
                            [_vm._v(" 微信支付 ")]
                          ),
                          _c("div", { staticClass: "paydemo-type-body" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.appPaywayList.indexOf("WX_NATIVE") >=
                                      0,
                                    expression:
                                      "appPaywayList.indexOf('WX_NATIVE') >= 0",
                                  },
                                ],
                                staticClass: "paydemo-type color-change",
                                class: {
                                  this: _vm.currentWayCode === "WX_NATIVE",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeCurrentWayCode(
                                      "WX_NATIVE",
                                      "codeImgUrl"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "paydemo-type-img",
                                  attrs: {
                                    src: require("@/assets/payTestImg/wx_native.svg"),
                                  },
                                }),
                                _c("span", { staticClass: "color-change" }, [
                                  _vm._v("微信二维码"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.appPaywayList.indexOf("WX_BAR") >= 0,
                                    expression:
                                      "appPaywayList.indexOf('WX_BAR') >= 0",
                                  },
                                ],
                                staticClass: "paydemo-type color-change",
                                class: {
                                  this: _vm.currentWayCode === "WX_BAR",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeCurrentWayCode(
                                      "WX_BAR",
                                      ""
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "paydemo-type-img",
                                  attrs: {
                                    src: require("@/assets/payTestImg/wx_bar.svg"),
                                  },
                                }),
                                _c("span", { staticClass: "color-change" }, [
                                  _vm._v("微信条码"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.appPaywayList.indexOf("WX_JSAPI") >=
                                      0,
                                    expression:
                                      "appPaywayList.indexOf('WX_JSAPI') >= 0",
                                  },
                                ],
                                staticClass: "paydemo-type color-change",
                                class: {
                                  this: _vm.currentWayCode === "WX_JSAPI",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeCurrentWayCode(
                                      "WX_JSAPI",
                                      "codeImgUrl"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "paydemo-type-img",
                                  attrs: {
                                    src: require("@/assets/payTestImg/wx_jsapi.svg"),
                                  },
                                }),
                                _c("span", { staticClass: "color-change" }, [
                                  _vm._v("公众号/小程序"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.appPaywayList.indexOf("WX_H5") >= 0,
                                    expression:
                                      "appPaywayList.indexOf('WX_H5') >= 0",
                                  },
                                ],
                                staticClass: "paydemo-type-h5",
                                class: { this: _vm.currentWayCode === "WX_H5" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeCurrentWayCode(
                                      "WX_H5",
                                      "payurl"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "paydemo-type-img",
                                  attrs: {
                                    src: require("@/assets/payTestImg/wx_h5.svg"),
                                  },
                                }),
                                _c("span", { staticClass: "color-change" }, [
                                  _vm._v("微信H5"),
                                ]),
                              ]
                            ),
                          ]),
                          _c("a-row"),
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form",
                                    {
                                      attrs: { form: _vm.form },
                                      on: { submit: _vm.handleSubmit },
                                    },
                                    [
                                      _c(
                                        "a-row",
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label: "购买项目",
                                                    "label-col": { span: 2 },
                                                    "wrapper-col": {
                                                      span: 15,
                                                      offset: 1,
                                                    },
                                                    colon: false,
                                                  },
                                                },
                                                [
                                                  _vm.configdata.saletype ==
                                                  "single"
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          _vm.pricelist,
                                                          function (item) {
                                                            return _c(
                                                              "a-tooltip",
                                                              {
                                                                attrs: {
                                                                  placement:
                                                                    "top",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "title",
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.tooltip
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                item.checktype ==
                                                                "radio"
                                                                  ? _c(
                                                                      "a-radio-group",
                                                                      {
                                                                        attrs: {
                                                                          "default-value":
                                                                            item.isforce ==
                                                                            0
                                                                              ? 0
                                                                              : 1,
                                                                          name: "radioGroup",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-radio",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                value: 1,
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  item.title
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "#f39800",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "￥" +
                                                                                    _vm._s(
                                                                                      item.price
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#999",
                                                                                        "font-size":
                                                                                          "8px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "（优惠期结束，恢复原价）"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        item.checktype ==
                                                                          "checkbox" &&
                                                                        item.servicename ==
                                                                          "express"
                                                                          ? _c(
                                                                              "a-radio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value: 1,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item.title
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#f39800",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "￥" +
                                                                                        _vm._s(
                                                                                          item.price
                                                                                        )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.configdata.saletype ==
                                                  "package"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "-30px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-radio-group",
                                                            {
                                                              model: {
                                                                value:
                                                                  _vm.packageid,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.packageid =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "packageid",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.pricelist,
                                                              function (item) {
                                                                return _c(
                                                                  "a-radio",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        item.id,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          placement:
                                                                            "top",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "template",
                                                                          {
                                                                            slot: "title",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.tooltip
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.title
                                                                            )
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "￥" +
                                                                                _vm._s(
                                                                                  item.price
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                            item.marketprice >
                                                                            0
                                                                              ? _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#999",
                                                                                        "text-decoration":
                                                                                          "line-through",
                                                                                        "font-size":
                                                                                          "8px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "（原价" +
                                                                                        _vm._s(
                                                                                          item.marketprice
                                                                                        ) +
                                                                                        "）"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-row",
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label: "购买数量",
                                                    labelCol: { span: 4 },
                                                    "wrapper-col": {
                                                      span: 1,
                                                      offset: 1,
                                                    },
                                                    colon: false,
                                                  },
                                                },
                                                [
                                                  _c("a-input-number", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value: [
                                                          "totalnum",
                                                          {
                                                            initialValue: 1,
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "购买数量!",
                                                              },
                                                            ],
                                                          },
                                                        ],
                                                        expression:
                                                          "[\n                              'totalnum',\n                              {\n                                initialValue: 1,\n                                rules: [\n                                  { required: true, message: '购买数量!' },\n                                ],\n                              },\n                            ]",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "inputNumber",
                                                      max: 100,
                                                      min: 1,
                                                    },
                                                    on: {
                                                      change: _vm.onChanges,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 12, pull: "4" } },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label: "购买单位",
                                                    disabled: true,
                                                    labelCol: { span: 4 },
                                                    wrapperCol: {
                                                      span: 10,
                                                      offset: 1,
                                                    },
                                                    colon: false,
                                                  },
                                                },
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value: [
                                                          "company",
                                                          {
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入购买单位!",
                                                              },
                                                            ],
                                                          },
                                                        ],
                                                        expression:
                                                          "[\n                              'company',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请输入购买单位!',\n                                  },\n                                ],\n                              },\n                            ]",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled: true,
                                                      placeholder:
                                                        "请输入购买单位全称",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-row",
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label: "信用代码",
                                                    disabled: true,
                                                    labelCol: { span: 2 },
                                                    wrapperCol: {
                                                      span: 8,
                                                      offset: 1,
                                                    },
                                                    colon: false,
                                                  },
                                                },
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value: [
                                                          "yscode",
                                                          _vm.validatorRules
                                                            .yscode,
                                                          {
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入统一社会信用代码!",
                                                              },
                                                            ],
                                                          },
                                                        ],
                                                        expression:
                                                          "[\n                              'yscode',\n                              validatorRules.yscode,\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请输入统一社会信用代码!',\n                                  },\n                                ],\n                              },\n                            ]",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "-30px",
                                                    },
                                                    attrs: {
                                                      disabled: true,
                                                      placeholder:
                                                        "请输入统一社会信用代码",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-row",
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label: "经办人",
                                                    labelCol: { span: 4 },
                                                    wrapperCol: {
                                                      span: 10,
                                                      offset: 1,
                                                    },
                                                    colon: false,
                                                  },
                                                },
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value: [
                                                          "name",
                                                          {
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入经办人!",
                                                              },
                                                            ],
                                                          },
                                                        ],
                                                        expression:
                                                          "[\n                              'name',\n                              {\n                                rules: [\n                                  { required: true, message: '请输入经办人!' },\n                                ],\n                              },\n                            ]",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled: true,
                                                      placeholder:
                                                        "请输入经办人姓名",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 12, pull: "4" } },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label: "手机号码",
                                                    disabled: true,
                                                    labelCol: { span: 4 },
                                                    wrapperCol: {
                                                      span: 10,
                                                      offset: 1,
                                                    },
                                                    colon: false,
                                                  },
                                                },
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value: [
                                                          "phone",
                                                          _vm.validatorRules
                                                            .phone,
                                                          {
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入手机号码!",
                                                              },
                                                            ],
                                                          },
                                                        ],
                                                        expression:
                                                          "[\n                              'phone',\n                              validatorRules.phone,\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请输入手机号码!',\n                                  },\n                                ],\n                              },\n                            ]",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled: true,
                                                      placeholder:
                                                        "请输入11位手机号码",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("a-col", { attrs: { span: 24 } }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "a-form",
                                      { attrs: { form: _vm.form } },
                                      [
                                        _c(
                                          "a-row",
                                          [
                                            _c(
                                              "a-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      label: "开票类型",
                                                      labelCol: { span: 2 },
                                                      wrapperCol: {
                                                        span: 8,
                                                        offset: 1,
                                                      },
                                                      colon: false,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-items":
                                                            "baseline",
                                                          "margin-left":
                                                            "-30px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-radio-group",
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "8px",
                                                            },
                                                            attrs: {
                                                              "default-value": 2,
                                                              name: "radioGroup",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.valueaw,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.valueaw =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "valueaw",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a-tooltip",
                                                              {
                                                                attrs: {
                                                                  placement:
                                                                    "top",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "title",
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "发票一到三个工作日，会发到您填写的邮箱"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.valueaw == 1
                                                          ? _c("div", [
                                                              _vm._v(
                                                                " 仅支持电子增值税普通发票 "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.valueaw == 2
                                                          ? _c("div", {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-row",
                                          [
                                            _c(
                                              "a-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      label: "电子邮箱",
                                                      labelCol: { span: 6 },
                                                      wrapperCol: {
                                                        span: 16,
                                                        offset: 1,
                                                      },
                                                      colon: false,
                                                    },
                                                  },
                                                  [
                                                    _c("a-input", {
                                                      directives: [
                                                        {
                                                          name: "decorator",
                                                          rawName:
                                                            "v-decorator",
                                                          value: [
                                                            "email",
                                                            _vm.validatorRules
                                                              .email,
                                                          ],
                                                          expression:
                                                            "['email', validatorRules.email]",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                      },
                                                      attrs: {
                                                        disabled: true,
                                                        placeholder:
                                                          "请输入用于接收电子发票和查询订单的邮箱，请使用网易或QQ邮箱",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _vm.valueaw == 1
                                                  ? _c(
                                                      "a-form-item",
                                                      {
                                                        attrs: {
                                                          label: "购方名称",
                                                          labelCol: { span: 6 },
                                                          wrapperCol: {
                                                            span: 15,
                                                            offset: 1,
                                                          },
                                                          colon: false,
                                                        },
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          directives: [
                                                            {
                                                              name: "decorator",
                                                              rawName:
                                                                "v-decorator",
                                                              value: [
                                                                "company",
                                                                {
                                                                  rules: [
                                                                    {
                                                                      required: true,
                                                                      message:
                                                                        "请输入购买单位!",
                                                                    },
                                                                  ],
                                                                },
                                                              ],
                                                              expression:
                                                                "[\n                                'company',\n                                {\n                                  rules: [\n                                    {\n                                      required: true,\n                                      message: '请输入购买单位!',\n                                    },\n                                  ],\n                                },\n                              ]",
                                                            },
                                                          ],
                                                          staticStyle: {
                                                            "margin-left":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.valueaw == 2
                                                  ? _c(
                                                      "a-form-item",
                                                      {
                                                        attrs: {
                                                          label: "购方名称",
                                                          labelCol: {
                                                            span: 6,
                                                            offset: 2,
                                                          },
                                                          wrapperCol: {
                                                            span: 10,
                                                            offset: 1,
                                                          },
                                                          colon: false,
                                                        },
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          directives: [
                                                            {
                                                              name: "decorator",
                                                              rawName:
                                                                "v-decorator",
                                                              value: [
                                                                "company",
                                                              ],
                                                              expression:
                                                                "['company']",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { span: 8, pull: "1" } },
                                              [
                                                _vm.valueaw == 1
                                                  ? _c(
                                                      "a-form-item",
                                                      {
                                                        attrs: {
                                                          label: "购方税号",
                                                          labelCol: {
                                                            span: 6,
                                                            offset: 2,
                                                          },
                                                          wrapperCol: {
                                                            span: 15,
                                                            offset: 1,
                                                          },
                                                          colon: false,
                                                        },
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          directives: [
                                                            {
                                                              name: "decorator",
                                                              rawName:
                                                                "v-decorator",
                                                              value: [
                                                                "yscode",
                                                                {
                                                                  rules: [
                                                                    {
                                                                      required: true,
                                                                      message:
                                                                        "请输入统一社会信用代码!",
                                                                    },
                                                                  ],
                                                                },
                                                              ],
                                                              expression:
                                                                "[\n                                'yscode',\n                                {\n                                  rules: [\n                                    {\n                                      required: true,\n                                      message: '请输入统一社会信用代码!',\n                                    },\n                                  ],\n                                },\n                              ]",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.valueaw == 2
                                                  ? _c(
                                                      "a-form-item",
                                                      {
                                                        attrs: {
                                                          label: "购方税号",
                                                          labelCol: {
                                                            span: 6,
                                                            offset: 2,
                                                          },
                                                          wrapperCol: {
                                                            span: 15,
                                                            offset: 1,
                                                          },
                                                          colon: false,
                                                        },
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          directives: [
                                                            {
                                                              name: "decorator",
                                                              rawName:
                                                                "v-decorator",
                                                              value: ["yscode"],
                                                              expression:
                                                                "['yscode']",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("div", {
                                          staticStyle: { height: "60px" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            {},
                            [
                              _c("a-col", { attrs: { span: 24 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "800px",
                                      margin: "auto",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-form",
                                      {
                                        staticStyle: { "margin-left": "248px" },
                                        attrs: {
                                          id: "ok",
                                          form: _vm.form,
                                          "label-col": { span: 5 },
                                          "wrapper-col": { span: 12 },
                                        },
                                      },
                                      [
                                        _c(
                                          "a-form-item",
                                          { attrs: { label: "" } },
                                          [
                                            _c("a-checkbox", {
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                              on: { change: _vm.onChangeqw },
                                              model: {
                                                value: _vm.checked,
                                                callback: function ($$v) {
                                                  _vm.checked = $$v
                                                },
                                                expression: "checked",
                                              },
                                            }),
                                            _vm._v(" 我已认真阅读并 "),
                                            _c(
                                              "span",
                                              {
                                                style: _vm.radioStyle,
                                                on: { click: _vm.boglook },
                                              },
                                              [_vm._v("同意协议")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "center",
                              },
                            },
                            [
                              _vm.companyinfostatus
                                ? _c(
                                    "a-button",
                                    {
                                      staticClass: "shibie",
                                      staticStyle: {
                                        "font-weight": "bold",
                                        width: "200px",
                                        height: "40px",
                                        background: "rgb(243, 152, 0)",
                                        "margin-left": "0px",
                                        "font-size": "15px",
                                      },
                                      attrs: { type: "danger" },
                                      on: { click: _vm.login },
                                    },
                                    [_vm._v("登录后提交订单")]
                                  )
                                : _vm._e(),
                              !_vm.companyinfostatus
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        width: "200px",
                                        height: "40px",
                                        background: "rgb(243, 152, 0)",
                                        "font-size": "15px",
                                      },
                                      attrs: {
                                        disabled: _vm.disabledlook,
                                        type: "danger",
                                      },
                                      on: { click: _vm.handleSubmit },
                                    },
                                    [_vm._v(" 提交订单 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", {
                            staticStyle: { width: "100%", height: "40px" },
                          }),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                "close-on-click-modal": false,
                                "close-on-press-escape": false,
                                visible: _vm.dialogVisible,
                                title: "智能图片处理",
                                width: "30%",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("div", { attrs: { id: "successbox" } }, [
                                _c("div", { staticClass: "infotip" }, [
                                  _vm._v(
                                    " 在本系统下单购买证书后，须自行登录" +
                                      _vm._s(_vm.configdata.certplatformtitle) +
                                      "提交证书申请。 您可以"
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#f39800",
                                        cursor: "pointer",
                                      },
                                      on: { click: _vm.openyw },
                                    },
                                    [_vm._v("点击此处")]
                                  ),
                                  _vm._v(
                                    "跳转到" +
                                      _vm._s(_vm.configdata.certplatformtitle) +
                                      "。 "
                                  ),
                                ]),
                                _c("div", { staticClass: "infotip" }, [
                                  _vm._v(
                                    " 提交证书申请后，还须在" +
                                      _vm._s(_vm.configdata.certplatformtitle) +
                                      "提交电子签章所需的公章图片。专业的图像处理软件处理图章耗时且操作繁杂，如果您需要人工智能自动化图章处理（自主操作，使用简单，安全可靠，5秒出图），请 "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#f39800",
                                        cursor: "pointer",
                                      },
                                      on: { click: _vm.openseal },
                                    },
                                    [_vm._v("点击此处")]
                                  ),
                                  _vm._v(" 前往购买。 "),
                                ]),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.dialogVisible = false
                                        },
                                      },
                                    },
                                    [_vm._v("关 闭")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                "confirm-loading": _vm.confirmLoading,
                destroyOnClose: true,
                maskClosable: false,
                visible: _vm.visible,
                title: "阅读条款",
                width: "45%",
              },
              on: { cancel: _vm.handleCancel },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "a-button",
                        {
                          staticClass: "footerbutton",
                          attrs: { disabled: _vm.disabled, type: "primary" },
                          on: { click: _vm.handleOk },
                        },
                        [_vm._v(_vm._s(_vm.valiBtn) + " ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("Contract", { attrs: { Fathertoson: _vm.Fathertoson } })],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: { title: "流程说明", width: "40%" },
              on: { ok: _vm.handleOkmodd },
              model: {
                value: _vm.visiblemodd,
                callback: function ($$v) {
                  _vm.visiblemodd = $$v
                },
                expression: "visiblemodd",
              },
            },
            [
              _c("p", [
                _c("b", [
                  _vm._v(
                    "灌装流程说明：企业在此下单后，请再自行前往证书运维管理平台上提交证书申请。"
                  ),
                ]),
              ]),
              _c("p", [_vm._v("Some contents...")]),
              _c("p", [_vm._v("Some contents...")]),
            ]
          ),
          _c("pay-test-modal", {
            ref: "payTestModal",
            on: {
              closeBarCode: function ($event) {
                _vm.$refs.payTestBarCode.visible = false
              },
            },
          }),
          _c("pay-test-bar-code", {
            ref: "payTestBarCode",
            on: {
              CodeAgainChange: _vm.testCodeChange,
              barCodeValue: _vm.barCodeChange,
            },
          }),
          _vm.Tips
            ? _c("div", { staticClass: "position" }, [
                _c("div", { staticClass: "position_cont" }, [
                  _c("div", { staticClass: "position_conttit" }, [
                    _vm._v("温馨提示"),
                  ]),
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "position_contbot" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "40px", "margin-top": "6px" },
                          attrs: { type: "danger", plain: "" },
                          on: { click: _vm.handleCanceslgiveup },
                        },
                        [_vm._v(" 放弃订购 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "40px", "margin-top": "6px" },
                          attrs: { type: "warning", plain: "" },
                          on: { click: _vm.handleCanceslcontinue },
                        },
                        [_vm._v(" 继续订购 ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.configdata.showonline == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    position: "fixed",
                    right: "25px",
                    bottom: "100px",
                    width: "100px",
                    overflow: "hidden",
                    cursor: "pointer",
                  },
                  on: { click: _vm.Gokefu },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "100px",
                      height: "90px",
                      margin: "10px auto 10px",
                    },
                    attrs: { src: _vm.icon_ren },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "block",
                        "text-align": "center",
                        margin: "0 auto",
                        width: "80px",
                        height: "30px",
                        "line-height": "30px",
                        "font-size": "14px",
                        color: "#fff",
                        background: "#f39800",
                        "border-radius": "5px",
                      },
                    },
                    [_vm._v("在线客服")]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "position_content" }, [
      _c("div", { staticStyle: { "margin-right": "50px" } }),
      _vm._v(
        " 省心服务可以帮您提供申请、罐装、邮寄的一站式服务，放弃省心服务可能无法及时获得CA证书，请再次确认是否放弃订购！ "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }